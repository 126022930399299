// Fonts
@import url("https://fonts.googleapis.com/css?family=Nunito");

// Variables
@import "variables";

// Bootstrap
@import "~bootstrap/scss/bootstrap";

// Font Awesome
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/brands";

html {
    position: relative;
    min-height: 100%;
}

// Navbar stuff

#sidebar.shown {
    left: 0;
}

.scutch,
.content.auth.scutch {
    margin-left: 194px;
}

.content {
    margin-left: 0px;
    margin-bottom: 50px;
}

#sidebar,
.content,
#top-nav {
    transition: all 0.5s ease-in-out;
}

@media only screen and (min-width: 768px) {
    #top-nav {
        display: none;
    }

    .content.auth {
        margin-left: 90px;
    }
}

@media only screen and (min-width: 869px) {
    .content.auth {
        margin-left: 194px;
    }
}

// Footer
.footer {
    position: absolute;
    bottom: 0;
    background-color: $body-bg;
    margin-left: 0;
}

// Overwrite endless.css
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
.uneditable-input {
    &.form-control {
        font-size: initial;
    }
}

.content {
    label,
    input,
    button,
    select,
    textarea.form-control {
        font-size: initial;
    }
}
